import TextButton from "components/commons/buttons/textButton/TextButton";

import * as Color from "constants/Colors";

export interface HeaderButtonStore {
	text: string; // buttonに表示するtext
	isAccentType: boolean; // true: 強調
}

export interface HeaderButtonProps extends HeaderButtonStore {
	action: () => void; // clickした時に実行する関数
}

function HeaderButton(props: HeaderButtonProps): JSX.Element {
	const backgroundColor: string = props.isAccentType === true ? "#18134E" : "#333333";

	return (
		<TextButton
			text={props.text}
			width="100%"
			height="100%"
			borderWidth="0"
			fontSize="0.8rem"
			fontColor={Color.DarkBaseWhiteColor}
			backgroundColor={backgroundColor}
			action={props.action}
		/>
	);
}

export default HeaderButton;
