import {OptionDefinition} from "interfaces/CommonInterfaces";
import * as InputElementStyle from "styles/InputElementStyle";

export interface DropDownListStore {
	options: OptionDefinition[]; // 選択肢
	///    valueType: string; //
}

export interface DropDownListProps extends DropDownListStore {}

function DropDownList(props: DropDownListProps): JSX.Element {
	function setSelectedValue(e: React.FormEvent<HTMLSelectElement>) {
		const value: string = e.currentTarget.value;
		console.log(value);
	}

	return (
		<InputElementStyle.Select
			onChange={(e: React.FormEvent<HTMLSelectElement>): void => {
				setSelectedValue(e);
			}}
		>
			{props.options.map((option: OptionDefinition): React.ReactElement => {
				const value: string = option.value.toString();

				return (
					<InputElementStyle.Option key={value} value={value}>
						{option.disp}
					</InputElementStyle.Option>
				);
			})}
		</InputElementStyle.Select>
	);
}

export default DropDownList;
