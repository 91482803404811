import {combineReducers} from "redux";

import {MainStore} from "components/main/Main";
import {MakingFormBasicStore} from "components/makingForm/basic/MakingFormBasic";
import {MakingFormElementsStore} from "components/makingForm/form/MakingFormElements";
import {MakingFormThanksStore} from "components/makingForm/thanks/MakingFormThanks";

import mainReducer from "reducers/MainReducer";
import makingFormBasicReducer from "reducers/MakingFormBasicReducer";
import makingFormElementsReducer from "reducers/MakingFormElementsReducer";
import makingFormThanksReducer from "reducers/MakingFormThanksReducer";

export interface RootStore {
	main: MainStore;
	makingFormBasic: MakingFormBasicStore;
	makingFormElements: MakingFormElementsStore;
	makingFormThanks: MakingFormThanksStore;
}

const rootReducer = combineReducers({
	main: mainReducer,
	makingFormBasic: makingFormBasicReducer,
	makingFormElements: makingFormElementsReducer,
	makingFormThanks: makingFormThanksReducer,
});

export default rootReducer;
