import {FlexboxHorizontal} from "styles/CommonStyle";
import {GeneralTextarea} from "styles/InputElementStyle";
import ImageButton from "components/commons/buttons/imageButton/ImageButton";

import imgXCircle from "resources/x-circle.svg";

import {GeneralInputProps} from "interfaces/StyleInterfaces";

export interface TextareaStore extends GeneralInputProps {
	currentValue: string;
	resetValue: string; // クリアした時の値
	width?: string;
	height?: string;
	required: boolean;
	clearButton: boolean; // 入力クリアボタンを表示するかどうか true:表示
}

export interface TextareaProps extends TextareaStore {
	setFunction: (value: string) => void; // 選択した時に値をセットする関数
}

function Textarea(props: TextareaProps): JSX.Element {
	const width: string = props.width === undefined ? "fit-content" : props.width;
	const height: string = props.height === undefined ? "24px" : props.height;
	const border: string = props.border === undefined ? "1px solid #767676" : props.border;
	const borderRadius: string = props.borderRadius === undefined ? "3px" : props.borderRadius;

	// 入力値をセットする
	function changeValue(e: React.ChangeEvent<HTMLTextAreaElement>): void {
		const inputValue: string = e.target.value;
		props.setFunction(inputValue);
	}

	// 入力値をリセットする
	function clearInputValue(): void {
		props.setFunction(props.resetValue);
	}

	return (
		<FlexboxHorizontal
			width={width}
			height={height}
			border={border}
			borderRadius={borderRadius}
			alignItems="center"
		>
			<GeneralTextarea
				value={props.currentValue}
				width="inherit;"
				height="100%"
				border="0"
				outline="none"
				required={props.required}
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>): void => {
					changeValue(e);
				}}
			/>
			{props.clearButton === true && props.currentValue !== props.resetValue && (
				<ImageButton
					imagePath={imgXCircle}
					alt="値をクリアする"
					imageWidth="24px"
					imageHeight="24px"
					buttonWidth="24px"
					buttonHeight="24px"
					action={(e?: React.MouseEvent) => {
						clearInputValue();
					}}
				/>
			)}
		</FlexboxHorizontal>
	);
}

export default Textarea;
