import styled from "styled-components";
import {
	GeneralButtonProps,
	PaddingStyledProps,
	SelectButtonStyledProps,
	SizeStyledProps,
} from "interfaces/StyleInterfaces";

export const GeneralTextButton = styled.button`
	${(props: GeneralButtonProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.fontSize === undefined) {
			return "";
		}
		return `font-size: ${props.fontSize}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.fontWeight === undefined) {
			return "";
		}
		return `font-weight: ${props.fontWeight}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.fontColor === undefined) {
			return "";
		}
		return `color: ${props.fontColor}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.backgroundColor === undefined) {
			return "";
		}
		return `background-color: ${props.backgroundColor}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.borderWidth === undefined) {
			return "";
		}
		return `border-width: ${props.borderWidth}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.radius === undefined) {
			return "";
		}
		return `border-radius: ${props.radius}`;
	}};
	${(props: GeneralButtonProps): string => {
		if (props.margin === undefined) {
			return "";
		}
		return `margin: ${props.margin}`;
	}};
`;

export interface ButtonGroupProps {
	isHorizontally: boolean; // true: 横並び false: 縦並び
	width?: string;
	height?: string;
	position?: string;
}

export const ButtonGroup = styled.div`
	display: flex;
	flex-flow: ${(props: ButtonGroupProps): string =>
		props.isHorizontally === true ? "row" : "column"};
	justify-content: flex-start;
	${(props: ButtonGroupProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: ButtonGroupProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	${(props: ButtonGroupProps): string => {
		if (props.position === undefined) {
			return "";
		}
		return `position: ${props.position}`;
	}};
`;

export const ButtonGroupComment = styled.div`
	font-size: 0.9rem;
`;

/*
export const RadioRegularStyle = styled.button`
	display: block;
	font-size: 1.2rem;
	${(props: SelectButtonStyledProps): string => {
		if (props.isSelected === true) {
			return `background-color: red`;
		}
		return ``;
	}};
`;
*/

export const RadioRegularStyle = styled.button`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	border: none;
	outline: none;

	box-flex: 1;
	width: 100%;
	height: 2rem;
	border: solid 1px blue;
	font-size: 1.2rem;
	font-weight: 500;
	font-style: normal;
	font-stretch: normal;
	line-height: 18px;
	letter-spacing: normal;
	white-space: nowrap;
	color: ${(props: SelectButtonStyledProps): string =>
		props.isSelected === true ? "white" : "blue"};
	background-color: ${(props: SelectButtonStyledProps): string =>
		props.isSelected === true ? "blue" : "white"};
	&:first-child {
		border-radius: ${(props: SelectButtonStyledProps): string =>
			props.isHorizontally === true ? "0.3rem 0 0 0.3rem" : "0.3rem 0.3rem 0 0"};
	}
	&:last-child {
		border-radius: ${(props: SelectButtonStyledProps): string =>
			props.isHorizontally === true ? "0 0.3rem 0.3rem 0" : "0 0 0.3rem 0.3rem"};
	}
	&:disabled {
		opacity: 0.4;
	}
`;
// border-radius: 左上 | 右上 | 右下 | 左下

//SelectButtonStyledProps

export const ImageButton = styled.button`
	display: block;
	${(props: SizeStyledProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: SizeStyledProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	padding: 0;
	margin: 0;
	border: none;
	background: none;
	line-height: 0;
	overflow: visible;
`;

export const ImgBlock = styled.img`
	display: block;
	${(props: PaddingStyledProps): string => {
		if (props.padding === undefined) {
			return "";
		}
		return `padding: ${props.padding}`;
	}};
`;

export const TabSelectButton = styled.button`
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;
	border: none;
	outline: none;
	box-flex: 1;
	width: 100%;
	height: 3.5rem;
	font-size: 1rem;
	font-weight: bold;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: normal;
	white-space: nowrap;
	color: black;
	background-color: white;
	border-top: ${(props: SelectButtonStyledProps): string =>
		props.isSelected === true ? "1rem solid red" : "1rem solid white"};
`;
