import * as React from "react";
import * as ReactDOM from "react-dom";

import DialogMainButton from "components/commons/buttons/dialogMainButton/DialogMainButton";
import DialogSubButton from "components/commons/buttons/dialogSubButton/DialogSubButton";

import * as LoginStyle from "styles/LoginStyle";
import * as CommonStyle from "styles/CommonStyle";

import * as Validation from "utilities/Validation";
import * as Messages from "constants/Messages";
import * as ui from "utilities/Ui";

export interface ReregisterPasswordSendMailStore {}

export interface ReregisterPasswordSendMailProps extends ReregisterPasswordSendMailStore {
	openLogin: () => void; // ログイン画面を開く
}

function ReregisterPasswordSendMail(props: ReregisterPasswordSendMailProps): JSX.Element {
	const [email, setEmail] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const [passwordInputType, setPasswordInputType] = React.useState<string>("password");
	const [isChecked, setIsChecked] = React.useState<boolean>(false);
	const [validationMessage, setValidationMessage] = React.useState<string>("");

	function changeInputValue(e: React.ChangeEvent<HTMLInputElement>): void {
		const fieldName: string = e.target.name;
		switch (fieldName) {
			case "email": {
				setEmail(e.target.value.replace(Validation.newLineValidationRegex, ""));
				break;
			}
			case "password": {
				setPassword(e.target.value.replace(Validation.newLineValidationRegex, ""));
				break;
			}
			case "changeType": {
				if (isChecked) {
					setPasswordInputType("password");
				} else {
					setPasswordInputType("text");
				}
				setIsChecked(!isChecked);
				break;
			}
			default: {
				break;
			}
		}
	}

	// login
	function login(): void {
		const isValidLoginId: boolean = Validation.isValidEmail(email);
		const isValidPassword: boolean = Validation.isValidPassword(password);
		if (!isValidPassword && !isValidLoginId) {
			setValidationMessage(Messages.LOGIN_ERROR_1);
			return;
		}
		if (!isValidLoginId) {
			setValidationMessage(Messages.LOGIN_ERROR_2);
			return;
		}
		if (!isValidPassword) {
			setValidationMessage(Messages.LOGIN_ERROR_3);
			return;
		}
		setValidationMessage("");
	}

	return (
		<>
			<div>
				<LoginStyle.TextError>{validationMessage}</LoginStyle.TextError>
				<LoginStyle.Text>ログインID（メールアドレス）</LoginStyle.Text>
				<br />
				<LoginStyle.TextBox
					type="email"
					name="email"
					value={email}
					autoComplete="email"
					maxLength={256}
					required
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						changeInputValue(e);
					}}
				/>
			</div>
			<CommonStyle.FlexboxHorizontal alignItems="center" justifyContent="center">
				<DialogMainButton
					text="パスワード|再登録開始"
					action={(): void => {
						login();
					}}
				/>
			</CommonStyle.FlexboxHorizontal>
			<CommonStyle.FlexboxHorizontal alignItems="center" justifyContent="center">
				<DialogSubButton
					text="ログイン|画面に戻る"
					action={(): void => {
						props.openLogin();
					}}
				/>
			</CommonStyle.FlexboxHorizontal>
		</>
	);
}

export default ReregisterPasswordSendMail;
