import RadioButton from "components/commons/buttons/radioButton/RadioButton";
import Textbox from "components/commons/textBox/Textbox";
import * as MakingFormStyle from "styles/MakingFormStyle";
import * as ButtonStyle from "styles/ButtonStyle";

import {OptionDefinition} from "interfaces/CommonInterfaces";

import * as CommonFunc from "utilities/CommonFunc";

export interface MakingFormBasicStore {
	title: string;
	isSetOpenPeriodDateBegin: boolean;
	openPeriodSpecifiedDateBegin: Date;
	isSetOpenPeriodDateEnd: boolean;
	openPeriodSpecifiedDateEnd: Date;
}

export interface MakingFormBasicProps extends MakingFormBasicStore {
	setTitle: (title: string) => void;
	setOpenPeriodIsSetDateBegin: (isSetDateBegin: boolean) => void;
	setOpenPeriodDateBegin: (openPeriodDateBegin: Date) => void;
	setOpenPeriodIsSetDateEnd: (isSetDateEnd: boolean) => void;
	setOpenPeriodDateEnd: (openPeriodDateEnd: Date) => void;
}

function MakingFormBasic(props: MakingFormBasicProps): JSX.Element {
	const radioOptionsIsSetDateBegin: OptionDefinition[] = [
		{value: false, disp: "指定しない", comment: "フォーム登録と同時に公開します。"},
		{value: true, disp: "日時指定", comment: "日時を指定します。"},
	];
	const radioOptionsIsSetDateEnd: OptionDefinition[] = [
		{value: false, disp: "無制限", comment: "終了日時を指定しません。"},
		{value: true, disp: "日時指定", comment: "日時を指定します。"},
	];

	const defaultValue: Date = CommonFunc.getNthDaysLater(1);

	return (
		<>
			{/*
		<MakingFormStyle.Content>

			*/}
			<MakingFormStyle.Title>タイトル</MakingFormStyle.Title>
			<MakingFormStyle.IndentationLevel1>
				<Textbox
					type="text"
					clearButton={true}
					currentValue={props.title}
					resetValue={""}
					required={false}
					width="90%"
					setFunction={(value: number | string | Date): void => {
						props.setTitle(value as string);
					}}
				/>
			</MakingFormStyle.IndentationLevel1>
			<br />
			<MakingFormStyle.Title>公開期間</MakingFormStyle.Title>
			<MakingFormStyle.IndentationLevel1>
				<span>開始日時</span>
				<RadioButton
					options={radioOptionsIsSetDateBegin}
					currentValue={props.isSetOpenPeriodDateBegin}
					styledButton={ButtonStyle.RadioRegularStyle}
					isHorizontally={true}
					showComment={false}
					width="90%"
					setFunction={(value: boolean | number | string): void => {
						props.setOpenPeriodIsSetDateBegin(value as boolean);
					}}
				/>
				{props.isSetOpenPeriodDateBegin === true && (
					<>
						<br />
						<Textbox
							type="datetime-local"
							clearButton={true}
							currentValue={props.openPeriodSpecifiedDateBegin}
							resetValue={defaultValue}
							min={defaultValue}
							required={false}
							setFunction={(value: number | string | Date): void => {
								props.setOpenPeriodDateBegin(value as Date);
							}}
						/>
					</>
				)}
				<br />
				<span>終了日時</span>
				<RadioButton
					options={radioOptionsIsSetDateEnd}
					currentValue={props.isSetOpenPeriodDateEnd}
					styledButton={ButtonStyle.RadioRegularStyle}
					isHorizontally={true}
					showComment={false}
					width="90%"
					setFunction={(value: boolean | number | string): void => {
						props.setOpenPeriodIsSetDateEnd(value as boolean);
					}}
				/>
				{props.isSetOpenPeriodDateEnd === true && (
					<>
						<br />
						<Textbox
							type="datetime-local"
							clearButton={true}
							currentValue={props.openPeriodSpecifiedDateEnd}
							resetValue={defaultValue}
							min={defaultValue}
							required={false}
							setFunction={(value: number | string | Date): void => {
								props.setOpenPeriodDateEnd(value as Date);
							}}
						/>
					</>
				)}
			</MakingFormStyle.IndentationLevel1>
		</>
	);
}

export default MakingFormBasic;
