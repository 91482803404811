import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import MakingForm, {MakingFormProps} from "components/makingForm/MakingForm";
import * as MainAction from "components/main/MainAction";
import * as ApplicationNames from "constants/ApplicationNames";

interface DispatchFromProps {
	openListingForms: () => void;
	//	setMainLayoutCurrentApp: (appName: string) => void;
}

export function mapStoreToProps(store: RootStore): Partial<MakingFormProps> {
	return {};
}

export function mapDispatchToProps(dispatch: Dispatch<MainAction.MainAction>): DispatchFromProps {
	return {
		openListingForms: (): void => {
			dispatch(MainAction.setMainLayoutCurrentApp(ApplicationNames.LISTING_FORMS));
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(MakingForm);
