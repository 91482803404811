import React from "react";
import {GeneralTextButton} from "styles/ButtonStyle";
import {GeneralButtonProps} from "interfaces/StyleInterfaces";

export interface TextButtonStore extends GeneralButtonProps {
	text: string;
	margin?: string; //margin: 上　右　下　左
}

export interface TextButtonProps extends TextButtonStore {
	action: () => void;
}

function TextButton(props: TextButtonProps): JSX.Element {
	const letters: string[] = props.text.split("|"); // 「|」で改行する

	return (
		<GeneralTextButton
			type="button"
			width={props.width}
			height={props.height}
			fontSize={props.fontSize}
			fontWeight={props.fontWeight}
			fontColor={props.fontColor}
			backgroundColor={props.backgroundColor}
			borderWidth={props.borderWidth}
			radius={props.radius}
			margin={props.margin}
			onClick={props.action}>
			{letters.map((letter: string, index: number): React.ReactElement => {
				return (
					<React.Fragment key={index}>
						{index !== 0 && <br />}
						{letter}
					</React.Fragment>
				);
			})}
		</GeneralTextButton>
	);
}

export default TextButton;
