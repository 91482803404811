import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import ListingForms, {ListingFormsProps} from "components/listingForms/ListingForms";
import * as MainAction from "components/main/MainAction";
import * as ApplicationNames from "constants/ApplicationNames";

interface DispatchFromProps {
	openMakingForm: () => void;
	logout: () => void;
}

export function mapStoreToProps(store: RootStore): Partial<ListingFormsProps> {
	return {};
}

export function mapDispatchToProps(dispatch: Dispatch<MainAction.MainAction>): DispatchFromProps {
	return {
		openMakingForm: (): void => {
			dispatch(MainAction.setMainLayoutCurrentApp(ApplicationNames.MAKING_FORM));
		},
		logout: (): void => {
			dispatch(MainAction.logout());
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(ListingForms);
