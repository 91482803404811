import ReactDOM from "react-dom";
import {createStore, compose, applyMiddleware} from "redux";
import {Provider} from "react-redux";
import rootReducer from "reducers/index";
import MainConnect from "components/main/MainConnect";

// declare global {
interface Window {
	__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
// }

declare const window: Window;

/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
/* eslint-enable */

const store = createStore(rootReducer /* preloadedState, */, composeEnhancers(applyMiddleware()));

ReactDOM.render(
	<Provider store={store}>
		<MainConnect />
	</Provider>,
	document.getElementById("main") as HTMLElement
);
