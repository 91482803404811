import styled from "styled-components";

import {GeneralInputProps} from "interfaces/StyleInterfaces";

import xCircle from "resources/x-circle.svg";
import imgDropDownArrow from "resources/dropdown-arrow.svg";

import * as CommonFunc from "utilities/CommonFunc";

export const GeneralInput = styled.input`
	${(props: GeneralInputProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.border === undefined) {
			return "";
		}
		return `border: ${props.border}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.borderRadius === undefined) {
			return "";
		}
		return `border-radius: ${props.borderRadius}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.outline === undefined) {
			return "";
		}
		return `outline: ${props.outline}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.margin === undefined) {
			return "";
		}
		return `margin: ${props.margin}`;
	}};
	&:required {
		background-color: pink;
	}
	&:valid {
		background-color: transparent;
	}
`;
//		background: url(${xCircle});
//&:not(:placeholder-shown) {
//	background-color: red;
//}
/*
${(props: GeneralInputProps): string => {
	if (
		props.clearButton === true &&
		CommonFunc.isNullOrUndefinedOrEmpty(props.value) === false
	) {
		// 値クリアボタン表示
		return `background: url(${xCircle})`;
	}
	return "background-color: yellow";
}};
*/

export const Select = styled.select`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: solid 1px #323232;
	border-right: solid 1px #323232;
	padding: 0 26px 0 2px;
	padding-left: 7px;
	background-color: #ffffff;
	background-image: url(${imgDropDownArrow});
	background-position: right;
	background-repeat: no-repeat;
	color: #323232;
`;

/*
width: ${(props: ThemeSizeStyledProps): string =>
	props.width !== undefined ? props.width : "152px"};
height: ${(props: ThemeSizeStyledProps): string =>
	props.height !== undefined ? props.height : "24px"};
max-width: ${(props: ThemeSizeStyledProps): string =>
	props.maxWidth ? props.maxWidth : "152px"};
*/

export const Option = styled.option``;

export const GeneralTextarea = styled.textarea`
	${(props: GeneralInputProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.border === undefined) {
			return "";
		}
		return `border: ${props.border}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.borderRadius === undefined) {
			return "";
		}
		return `border-radius: ${props.borderRadius}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.outline === undefined) {
			return "";
		}
		return `outline: ${props.outline}`;
	}};
	${(props: GeneralInputProps): string => {
		if (props.margin === undefined) {
			return "";
		}
		return `margin: ${props.margin}`;
	}};
	&:required {
		background-color: pink;
	}
	&:valid {
		background-color: transparent;
	}
`;
