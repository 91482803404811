import * as Actions from "constants/Actions";

// ■■■■■■■■■■ interface
export interface SetTitle {
	type: Actions.TYPE_MAKINGFORMBASIC_SET_TITLE;
	args: {title: string};
}
export interface SetIsSetOpenPeriodDateBegin {
	type: Actions.TYPE_MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN;
	args: {isSetOpenPeriodDateBegin: boolean};
}
export interface SetOpenPeriodSpecifiedDateBegin {
	type: Actions.TYPE_MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN;
	args: {openPeriodSpecifiedDateBegin: Date};
}
export interface SetIsSetOpenPeriodDateEnd {
	type: Actions.TYPE_MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END;
	args: {isSetOpenPeriodDateEnd: boolean};
}
export interface SetOpenPeriodSpecifiedDateEnd {
	type: Actions.TYPE_MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END;
	args: {openPeriodSpecifiedDateEnd: Date};
}

// ■■■■■■■■■■ type
export type MakingFormBasicAction =
	| SetTitle
	| SetIsSetOpenPeriodDateBegin
	| SetOpenPeriodSpecifiedDateBegin
	| SetIsSetOpenPeriodDateEnd
	| SetOpenPeriodSpecifiedDateEnd;

// ■■■■■■■■■■ function
export function setTitle(title: string): SetTitle {
	return {
		type: Actions.MAKINGFORMBASIC_SET_TITLE,
		args: {
			title,
		},
	};
}
export function setOpenPeriodIsSetDateBegin(
	isSetOpenPeriodDateBegin: boolean
): SetIsSetOpenPeriodDateBegin {
	return {
		type: Actions.MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN,
		args: {
			isSetOpenPeriodDateBegin,
		},
	};
}
export function setOpenPeriodDateBegin(
	openPeriodSpecifiedDateBegin: Date
): SetOpenPeriodSpecifiedDateBegin {
	return {
		type: Actions.MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN,
		args: {
			openPeriodSpecifiedDateBegin,
		},
	};
}
export function setOpenPeriodIsSetDateEnd(
	isSetOpenPeriodDateEnd: boolean
): SetIsSetOpenPeriodDateEnd {
	return {
		type: Actions.MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END,
		args: {
			isSetOpenPeriodDateEnd,
		},
	};
}
export function setOpenPeriodDateEnd(
	openPeriodSpecifiedDateEnd: Date
): SetOpenPeriodSpecifiedDateEnd {
	return {
		type: Actions.MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END,
		args: {
			openPeriodSpecifiedDateEnd,
		},
	};
}
