import Textbox from "components/commons/textBox/Textbox";
import Textarea from "components/commons/textarea/Textarea";
import * as MakingFormStyle from "styles/MakingFormStyle";

export interface MakingFormThanksStore {
	title: string;
	message: string;
}

export interface MakingFormThanksProps extends MakingFormThanksStore {
	setTitle: (title: string) => void;
	setMessage: (message: string) => void;
}

function MakingFormThanks(props: MakingFormThanksProps): JSX.Element {
	return (
		<>
			<MakingFormStyle.Title>タイトル</MakingFormStyle.Title>
			<MakingFormStyle.IndentationLevel1>
				<Textbox
					type="text"
					clearButton={true}
					currentValue={props.title}
					resetValue={""}
					required={false}
					width="90%"
					setFunction={(value: number | string | Date): void => {
						props.setTitle(value as string);
					}}
				/>
			</MakingFormStyle.IndentationLevel1>
			<br />
			<MakingFormStyle.Title>お礼コメント</MakingFormStyle.Title>
			<MakingFormStyle.IndentationLevel1>
				<Textarea
					clearButton={true}
					currentValue={props.message}
					resetValue={""}
					required={false}
					width="90%"
					height="10rem"
					setFunction={(value: number | string | Date): void => {
						props.setMessage(value as string);
					}}
				/>
			</MakingFormStyle.IndentationLevel1>
		</>
	);
}

export default MakingFormThanks;
