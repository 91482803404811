import styled from "styled-components";

import {Flexbox} from "styles/CommonStyle";
import * as Color from "constants/Colors";

import {ColorStyledProps} from "interfaces/StyleInterfaces";

import svgDraggable from "resources/draggable.svg";

export const Outside = styled(Flexbox)`
	flex-direction: column;
	margin: 5px;
`;

//	margin-left: 10px;

export const Title = styled.h2`
	margin: 0;
	padding: 0 8px;
	border-left: 10px solid ${Color.ReqizThemeColor};
	font-size: 1.2em;
`;

export const Content = styled.div`
	width: 90%;
`;

export const IndentationLevel1 = styled.div`
	margin-left: 15px;
`;

export const Input = styled.input``;

export const Radio = styled.input``;

export const ContentMargin = styled.div`
	margin: 5px;
`;

export const TabStyleFrame = styled.div`
	display: grid;
	grid-template-rows: 3.5rem 1fr;
	margin: 5px;
`;

export const TabStyleContent = styled.div``;
//	overflow-y: scroll;

export const FormElementTable = styled.table``;

export const FormElementTableThead = styled.thead`
	font-size: 0.6rem;
`;

export const FormElementTableTheadTr = styled.tr``;

// 表のタイトルを固定する
export const FormElementTableTheadTh = styled.th`
	position: sticky;
	top: 0;
	background-color: white;
`;

export const FormElementTableTBody = styled.tbody``;

export const FormElementTableTBodyTr = styled.tr`
	&:nth-child(odd) {
		background-color: #eeeeee;
	}
	&:nth-child(even) {
		background-color: #fafafa;
	}
`;

export const FormElementTableTBodyTd = styled.td`
	${(props: ColorStyledProps): string => {
		if (props.color === undefined) {
			return "";
		}
		return `color: ${props.color}`;
	}};
	${(props: ColorStyledProps): string => {
		if (props.backgroundColor === undefined) {
			return "";
		}
		return `background-color: ${props.backgroundColor}`;
	}};
`;

export const FormElementTableTBodyTdDraggable = styled.td`
	background-image: url(${svgDraggable});
	background-position: center;
	background-repeat: no-repeat;
	${(props: ColorStyledProps): string => {
		if (props.color === undefined) {
			return "";
		}
		return `color: ${props.color}`;
	}};
	${(props: ColorStyledProps): string => {
		if (props.backgroundColor === undefined) {
			return "";
		}
		return `background-color: ${props.backgroundColor}`;
	}};
`;
