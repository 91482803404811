import HeaderButton from "components/commons/buttons/headerButton/HeaderButton";

import * as ScreenLayoutsStyle from "styles/ScreenLayoutsStyle";

import Textbox from "components/commons/textBox/Textbox";
import React from "react";

import DropDownList from "components/commons/dropDownList/DropDownList";
import {OptionDefinition} from "interfaces/CommonInterfaces";

import * as CommonFunc from "utilities/CommonFunc";

export interface ListingFormsStore {}

export interface ListingFormsProps extends ListingFormsStore {
	openMakingForm: () => void;
	logout: () => void;
}

function ListingForms(props: ListingFormsProps): JSX.Element {
	const [value, setValue] = React.useState<number>(0);
	const [textValue, setTextValue] = React.useState<string>("");

	const selectList: OptionDefinition[] = [
		{value: "text", disp: "テキスト入力"},
		{value: "address", disp: "住所"},
		{value: "select", disp: "選択入力"},
	];

	function action(): void {
		console.log("ボタンタップ");
	}

	return (
		<>
			<ScreenLayoutsStyle.MainLayoutHeader>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="フォーム|作成"
						isAccentType
						action={(): void => {
							props.openMakingForm();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer></ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="お客様情報"
						isAccentType={false}
						action={(): void => {
							action();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="サイン|アウト"
						isAccentType={false}
						action={(): void => {
							props.logout();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
			</ScreenLayoutsStyle.MainLayoutHeader>
			<ScreenLayoutsStyle.MainLayoutContentFixed></ScreenLayoutsStyle.MainLayoutContentFixed>
			<ScreenLayoutsStyle.MainLayoutContent>
				<h1>コンピュータの</h1>
				<p>あのイーハトーヴォのすきとおった風夏でも底に冷たさをもつ青いそら</p>
				<br />

				<Textbox
					type="number"
					clearButton={true}
					currentValue={value}
					resetValue={0}
					required={false}
					setFunction={(value: number | string | Date): void => {
						setValue(value as number);
					}}
				/>

				<br />
				<br />
				<Textbox
					type="text"
					clearButton={true}
					currentValue={textValue}
					resetValue={""}
					required={false}
					setFunction={(value: number | string | Date): void => {
						setTextValue(value as string);
					}}
				/>
				<br />
				<br />
				<input type="text"></input>
				<br />
				<br />
				<input type="datetime-local"></input>
				<br />
				<br />
				<input type="date"></input>
				<br />
				<br />
				<DropDownList options={selectList} />
				<br />
				<br />
				<select name="pets" id="pet-select">
					<option value="dog">テキスト入力</option>
					<option value="cat">住所</option>
					<option value="hamster">選択入力</option>
				</select>
				<br />
				<br />
				<p>
					ああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああああ
				</p>
			</ScreenLayoutsStyle.MainLayoutContent>
		</>
	);
}

export default ListingForms;
