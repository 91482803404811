import * as Actions from "constants/Actions";

// ■■■■■■■■■■ interface
export interface SetTitle {
	type: Actions.TYPE_MAKINGFORMTHANKS_SET_TITLE;
	args: {title: string};
}
export interface SetMessage {
	type: Actions.TYPE_MAKINGFORMTHANKS_SET_MESSAGE;
	args: {message: string};
}

// ■■■■■■■■■■ type
export type MakingFormThanksAction = SetTitle | SetMessage;

// ■■■■■■■■■■ function
export function setTitle(title: string): SetTitle {
	return {
		type: Actions.MAKINGFORMTHANKS_SET_TITLE,
		args: {
			title,
		},
	};
}
export function setMessage(message: string): SetMessage {
	return {
		type: Actions.MAKINGFORMTHANKS_SET_MESSAGE,
		args: {
			message,
		},
	};
}
