import * as Actions from "constants/Actions";
import {MakingFormBasicStore} from "components/makingForm/basic/MakingFormBasic";
import {MakingFormBasicAction} from "components/makingForm/basic/MakingFormBasicAction";

import * as CommonFunc from "utilities/CommonFunc";

const defaultDate: Date = CommonFunc.getNthDaysLater(1);

export const initialStore: MakingFormBasicStore = {
	title: "",
	isSetOpenPeriodDateBegin: false,
	openPeriodSpecifiedDateBegin: defaultDate,
	isSetOpenPeriodDateEnd: false,
	openPeriodSpecifiedDateEnd: defaultDate,
};

export function makingFormBasicReducer(
	makingFormBasicStore: MakingFormBasicStore = initialStore,
	makingFormBasicAction: MakingFormBasicAction
): MakingFormBasicStore {
	switch (makingFormBasicAction.type) {
		case Actions.MAKINGFORMBASIC_SET_TITLE: {
			const title: string = makingFormBasicAction.args.title;
			return {...makingFormBasicStore, title: title};
		}
		case Actions.MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN: {
			const value: boolean = makingFormBasicAction.args.isSetOpenPeriodDateBegin;
			return {...makingFormBasicStore, isSetOpenPeriodDateBegin: value};
		}
		case Actions.MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN: {
			const value: Date = makingFormBasicAction.args.openPeriodSpecifiedDateBegin;
			return {
				...makingFormBasicStore,
				openPeriodSpecifiedDateBegin: value,
			};
		}
		case Actions.MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END: {
			const value: boolean = makingFormBasicAction.args.isSetOpenPeriodDateEnd;
			return {...makingFormBasicStore, isSetOpenPeriodDateEnd: value};
		}
		case Actions.MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END: {
			const value: Date = makingFormBasicAction.args.openPeriodSpecifiedDateEnd;
			return {...makingFormBasicStore, openPeriodSpecifiedDateEnd: value};
		}
		default: {
			return {...makingFormBasicStore};
		}
	}
}

export default makingFormBasicReducer;
