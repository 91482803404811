import {FlexboxHorizontal} from "styles/CommonStyle";
import {GeneralInput} from "styles/InputElementStyle";
import ImageButton from "components/commons/buttons/imageButton/ImageButton";

import imgXCircle from "resources/x-circle.svg";

import {GeneralInputProps} from "interfaces/StyleInterfaces";

import {getDateString} from "utilities/CommonFunc";

export interface TextboxStore extends GeneralInputProps {
	type: string;
	currentValue: number | string | Date;
	resetValue: number | string | Date; // クリアした時の値
	min?: number | string | Date;
	max?: number | string | Date;
	required: boolean;
	clearButton: boolean; // 入力クリアボタンを表示するかどうか true:表示
}

export interface TextboxProps extends TextboxStore {
	setFunction: (value: number | string | Date) => void; // 選択した時に値をセットする関数
}

function Textbox(props: TextboxProps): JSX.Element {
	const width: string = props.width === undefined ? "fit-content" : props.width;
	const height: string = props.height === undefined ? "24px" : props.height;
	const border: string = props.border === undefined ? "1px solid #767676" : props.border;
	const borderRadius: string = props.borderRadius === undefined ? "3px" : props.borderRadius;

	let currentValue: number | string = props.currentValue as number | string; // Dateは下記処理で文字列に変換
	let min: undefined | number | string = props.min as undefined | number | string; // Dateは下記処理で文字列に変換
	let max: undefined | number | string = props.max as undefined | number | string; // Dateは下記処理で文字列に変換
	switch (props.type) {
		case "date": {
			currentValue = getDateString(props.currentValue as Date, false, true);
			if (props.min !== undefined) {
				min = getDateString(props.min as Date, false, true);
			}
			if (props.max !== undefined) {
				max = getDateString(props.max as Date, false, true);
			}
			break;
		}
		case "datetime-local": {
			currentValue = getDateString(props.currentValue as Date, false, false);
			if (props.min !== undefined) {
				min = getDateString(props.min as Date, false, false);
			}
			if (props.max !== undefined) {
				max = getDateString(props.max as Date, false, false);
			}
			break;
		}
		default: {
			break;
		}
	}

	// 入力値をセットする
	function changeValue(e: React.ChangeEvent<HTMLInputElement>): void {
		const inputValue: string = e.target.value;
		switch (props.type) {
			case "date": {
				const setValue: Date = new Date(`${inputValue} 00:00:00 +0900`);
				props.setFunction(setValue);
				break;
			}
			case "datetime-local": {
				const setValue: Date = new Date(inputValue);
				props.setFunction(setValue);
				break;
			}
			case "number": {
				const setValue: number = parseInt(inputValue, 10);
				props.setFunction(setValue);
				break;
			}
			default: {
				props.setFunction(inputValue);
				break;
			}
		}
	}

	// 入力値をリセットする
	function clearInputValue(): void {
		props.setFunction(props.resetValue);
	}

	// クリアボタンの表示条件
	function getClearButtonDispCondition(): boolean {
		let result: boolean = false;

		switch (props.type) {
			case "date":
			case "datetime-local": {
				const current = props.currentValue as Date;
				const reset = props.resetValue as Date;
				result = current.getTime() !== reset.getTime();
				break;
			}
			default: {
				result = props.currentValue !== props.resetValue;
				break;
			}
		}

		return result;
	}

	return (
		<FlexboxHorizontal
			width={width}
			height={height}
			border={border}
			borderRadius={borderRadius}
			alignItems="center"
		>
			<GeneralInput
				type={props.type}
				value={currentValue}
				min={min}
				max={max}
				width="inherit;"
				height="100%"
				border="0"
				outline="none"
				required={props.required}
				onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
					changeValue(e);
				}}
			/>
			{props.clearButton === true && getClearButtonDispCondition() && (
				<ImageButton
					imagePath={imgXCircle}
					alt="値をクリアする"
					imageWidth="24px"
					imageHeight="24px"
					buttonWidth="24px"
					buttonHeight="24px"
					action={(e?: React.MouseEvent) => {
						clearInputValue();
					}}
				/>
			)}
		</FlexboxHorizontal>
	);
}

export default Textbox;
