import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import Main, {MainStore} from "components/main/Main";
import * as MainAction from "components/main/MainAction";

interface DispatchFromProps {}

export function mapStoreToProps(store: RootStore): MainStore {
	return {
		email: store.main.email,
		isLoggedIn: store.main.isLoggedIn,
		centeringLayoutCurrentApp: store.main.centeringLayoutCurrentApp,
		mainLayoutCurrentApp: store.main.mainLayoutCurrentApp,
	};
}

export function mapDispatchToProps(dispatch: Dispatch<MainAction.MainAction>): DispatchFromProps {
	return {};
}

export default connect(mapStoreToProps, mapDispatchToProps)(Main);
