import styled from "styled-components";
import {GeneralMarginProps, GeneralInputProps} from "interfaces/StyleInterfaces";

export interface FlexboxProps extends GeneralMarginProps, GeneralInputProps {
	alignItems?: string;
	justifyContent?: string;
	color?: string;
	backgroundColor?: string;
	fontSize?: string;
}

// flexbox
export const Flexbox = styled.div`
	display: flex;
`;

// flexbox縦並び
export const FlexboxVertical = styled(Flexbox)`
	flex-direction: column;
	${(props: FlexboxProps): string => {
		if (props.top === undefined) {
			return "";
		}
		return `margin-top: ${props.top}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.bottom === undefined) {
			return "";
		}
		return `margin-bottom: ${props.bottom}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.left === undefined) {
			return "";
		}
		return `margin-left: ${props.left}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.right === undefined) {
			return "";
		}
		return `margin-right: ${props.right}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.alignItems === undefined) {
			return "";
		}
		return `align-items: ${props.alignItems}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.justifyContent === undefined) {
			return "";
		}
		return `justify-content: ${props.justifyContent}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.color === undefined) {
			return "";
		}
		return `color: ${props.color}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.backgroundColor === undefined) {
			return "";
		}
		return `background-color: ${props.backgroundColor}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.fontSize === undefined) {
			return "";
		}
		return `font-size: ${props.fontSize}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.border === undefined) {
			return "";
		}
		return `border: ${props.border}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.borderRadius === undefined) {
			return "";
		}
		return `border-radius: ${props.borderRadius}`;
	}};
`;

// flexbox横並び
export const FlexboxHorizontal = styled(Flexbox)`
	flex-direction: row;
	${(props: FlexboxProps): string => {
		if (props.top === undefined) {
			return "";
		}
		return `margin-top: ${props.top}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.bottom === undefined) {
			return "";
		}
		return `margin-bottom: ${props.bottom}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.left === undefined) {
			return "";
		}
		return `margin-left: ${props.left}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.right === undefined) {
			return "";
		}
		return `margin-right: ${props.right}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.alignItems === undefined) {
			return "";
		}
		return `align-items: ${props.alignItems}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.justifyContent === undefined) {
			return "";
		}
		return `justify-content: ${props.justifyContent}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.color === undefined) {
			return "";
		}
		return `color: ${props.color}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.backgroundColor === undefined) {
			return "";
		}
		return `background-color: ${props.backgroundColor}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.fontSize === undefined) {
			return "";
		}
		return `font-size: ${props.fontSize}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.width === undefined) {
			return "";
		}
		return `width: ${props.width}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.height === undefined) {
			return "";
		}
		return `height: ${props.height}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.border === undefined) {
			return "";
		}
		return `border: ${props.border}`;
	}};
	${(props: FlexboxProps): string => {
		if (props.borderRadius === undefined) {
			return "";
		}
		return `border-radius: ${props.borderRadius}`;
	}};
`;

// マージンをとるためのDIV
export const AdjustPositionDiv = styled.div`
	${(props: GeneralMarginProps): string => {
		if (props.top === undefined) {
			return "";
		}
		return `margin-top: ${props.top}`;
	}};
	${(props: GeneralMarginProps): string => {
		if (props.bottom === undefined) {
			return "";
		}
		return `margin-bottom: ${props.bottom}`;
	}};
	${(props: GeneralMarginProps): string => {
		if (props.left === undefined) {
			return "";
		}
		return `margin-left: ${props.left}`;
	}};
	${(props: GeneralMarginProps): string => {
		if (props.right === undefined) {
			return "";
		}
		return `margin-right: ${props.right}`;
	}};
`;
