import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import RegisterAccount, {RegisterAccountProps} from "components/registerAccount/RegisterAccount";
import * as MainAction from "components/main/MainAction";
import * as ApplicationNames from "constants/ApplicationNames";

interface DispatchFromProps {
	openLogin: () => void; // ログイン画面を開く
}

export function mapStoreToProps(store: RootStore): Partial<RegisterAccountProps> {
	return {};
}

export function mapDispatchToProps(dispatch: Dispatch<MainAction.MainAction>): DispatchFromProps {
	return {
		openLogin: (): void => {
			dispatch(MainAction.setCenteringLayoutCurrentApp(ApplicationNames.LOGIN));
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(RegisterAccount);
