import HeaderButton from "components/commons/buttons/headerButton/HeaderButton";

import * as ScreenLayoutsStyle from "styles/ScreenLayoutsStyle";

export interface ViewingUserInfoFormStore {}

export interface ViewingUserInfoFormProps extends ViewingUserInfoFormStore {}

function ViewingUserInfo(props: ViewingUserInfoFormProps): JSX.Element {
	function action(): void {
		console.log("ボタンタップ");
	}

	return (
		<>
			<ScreenLayoutsStyle.MainLayoutHeader>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="登録する"
						isAccentType
						action={(): void => {
							action();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="プレビュー"
						isAccentType={false}
						action={(): void => {
							action();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer></ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="戻る"
						isAccentType={false}
						action={(): void => {
							action();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
			</ScreenLayoutsStyle.MainLayoutHeader>
			<div>
				<h1>ユーザー情報</h1>
			</div>
		</>
	);
}

export default ViewingUserInfo;
