import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import MakingFormThanks, {
	MakingFormThanksStore,
} from "components/makingForm/thanks/MakingFormThanks";
import * as MakingFormThanksAction from "components/makingForm/thanks/MakingFormThanksAction";

interface DispatchFromProps {
	setTitle: (title: string) => void;
	setMessage: (message: string) => void;
}

export function mapStoreToProps(store: RootStore): MakingFormThanksStore {
	return {
		title: store.makingFormThanks.title,
		message: store.makingFormThanks.message,
	};
}

export function mapDispatchToProps(
	dispatch: Dispatch<MakingFormThanksAction.MakingFormThanksAction>
): DispatchFromProps {
	return {
		setTitle: (title: string): void => {
			dispatch(MakingFormThanksAction.setTitle(title));
		},
		setMessage: (message: string): void => {
			dispatch(MakingFormThanksAction.setMessage(message));
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(MakingFormThanks);
