import React from "react";

import TextButton from "components/commons/buttons/textButton/TextButton";
import svgDraggable from "resources/draggable.svg";
import svgTest from "resources/ic-error_20px.svg";

import {
	FormElementTable,
	FormElementTableThead,
	FormElementTableTBody,
	FormElementTableTheadTr,
	FormElementTableTheadTh,
	FormElementTableTBodyTr,
	FormElementTableTBodyTd,
	FormElementTableTBodyTdDraggable,
} from "styles/MakingFormStyle";

import * as Colors from "constants/Colors";
import * as DragFrom from "constants/DragFrom";
import * as CommonFunc from "utilities/CommonFunc";
import {url} from "inspector";

export interface FormElementProperty {
	isRequired: boolean; // true: 入力必須
	title: string; // タイトル
	type: string; // 要素タイプ
	property: string; // 要素データ
}

export interface MakingFormElementsStore {
	formElements: FormElementProperty[];
}

export interface MakingFormElementsProps extends MakingFormElementsStore {
	addElementToAbove: (index: number) => void;
	addElementToBelow: (index: number) => void;
	deleteElement: (index: number) => void;
}

function MakingFormElements(props: MakingFormElementsProps): JSX.Element {
	const noSetting: JSX.Element = (
		<span>
			未設定
			<br />
			[設定]をタップして要素を選択・設定して下さい。
		</span>
	);

	function settingElement() {
		console.log("設定する");
	}

	function dragFavoriteItem(e: React.DragEvent<HTMLElement>, targetIndex: number): void {
		const dragId = `dragTR${targetIndex}`;

		const dimg = document.getElementById(dragId);
		//		e.dataTransfer.setDragImage(dimg as Element, 100, 50);

		let imageElement = new Image();
		imageElement.src = svgTest;
		e.dataTransfer.setDragImage(imageElement, 50, 50);

		e.dataTransfer.setData("text/plain", `${DragFrom.FAVORITE_LIST}:${targetIndex}`);
	}

	function dropFavoriteItem(e: React.DragEvent<HTMLElement>, transferIndex: number): void {
		e.preventDefault();
		const dropDatas: string[] = CommonFunc.getDataFromEvent(e);
		if (dropDatas[1] === undefined || dropDatas[1] === "") {
			return;
		}

		// お気に入りリストからドラッグした銘柄のみ登録する
		if (dropDatas[0] !== DragFrom.FAVORITE_LIST) {
			return;
		}

		const targetIndex: number = parseInt(dropDatas[1], 10);
		if (targetIndex < 0) {
			return;
		}

		/*
		const isUpper: boolean = CommonFunc.getIsUpperFromEvent(e);
		sortFavoriteItem(targetIndex, transferIndex, isUpper);
		isNeedSort = true;
		*/
	}

	return (
		<>
			<FormElementTable>
				<FormElementTableThead>
					<FormElementTableTheadTr>
						<FormElementTableTheadTh>要素設定</FormElementTableTheadTh>
						<FormElementTableTheadTh>必須</FormElementTableTheadTh>
						<FormElementTableTheadTh>
							タイトルまたは
							<br />
							質問内容
						</FormElementTableTheadTh>
						<FormElementTableTheadTh>行追加</FormElementTableTheadTh>
						<FormElementTableTheadTh>移動</FormElementTableTheadTh>
					</FormElementTableTheadTr>
				</FormElementTableThead>
				<FormElementTableTBody>
					{props.formElements.map((element: FormElementProperty, index: number) => {
						const dragId = `dragTR${index}`;
						const requiredColor =
							element.isRequired === true ? Colors.RequiredElementColor : "";

						return (
							<FormElementTableTBodyTr key={index} id={dragId}>
								{/** 設定ボタン */}
								<FormElementTableTBodyTd width="15%">
									<TextButton
										text="設定"
										width="100%"
										height="4rem"
										radius="0"
										action={(): void => {
											settingElement();
										}}
									/>
									<br />
									{props.formElements.length > 1 && (
										<TextButton
											text="削除"
											width="100%"
											height="2rem"
											radius="0"
											action={(): void => {
												props.deleteElement(index);
											}}
										/>
									)}
								</FormElementTableTBodyTd>
								{/** 必須 */}
								<FormElementTableTBodyTd
									width="5%"
									backgroundColor={requiredColor}
								></FormElementTableTBodyTd>

								<FormElementTableTBodyTd width="50%" draggable>
									{element.title === "" || element.type === ""
										? noSetting
										: element.title}
								</FormElementTableTBodyTd>
								<FormElementTableTBodyTd width="15%">
									<TextButton
										text="👆"
										width="100%"
										height="3rem"
										radius="0"
										action={(): void => {
											props.addElementToAbove(index);
										}}
									/>
									<br />
									<TextButton
										text="👇"
										width="100%"
										height="3rem"
										radius="0"
										action={(): void => {
											props.addElementToBelow(index);
										}}
									/>
								</FormElementTableTBodyTd>
								<FormElementTableTBodyTdDraggable
									width="15%"
									draggable
									onDragStart={(e: React.DragEvent<HTMLElement>): void =>
										dragFavoriteItem(e, index)
									}
									onDragOver={(e: React.DragEvent<HTMLElement>): void =>
										CommonFunc.handleDragOver(e)
									}
									onDrop={(e: React.DragEvent<HTMLElement>): void =>
										dropFavoriteItem(e, index)
									}
								></FormElementTableTBodyTdDraggable>
							</FormElementTableTBodyTr>
						);
					})}
				</FormElementTableTBody>
			</FormElementTable>
		</>
	);
}

export default MakingFormElements;
