import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import MakingFormElements, {
	MakingFormElementsStore,
} from "components/makingForm/form/MakingFormElements";
import * as MakingFormElementsAction from "components/makingForm/form/MakingFormElementsAction";

interface DispatchFromProps {
	addElementToAbove: (index: number) => void;
	addElementToBelow: (index: number) => void;
	deleteElement: (index: number) => void;
}

export function mapStoreToProps(store: RootStore): MakingFormElementsStore {
	return {
		formElements: store.makingFormElements.formElements,
	};
}

export function mapDispatchToProps(
	dispatch: Dispatch<MakingFormElementsAction.MakingFormElementsAction>
): DispatchFromProps {
	return {
		addElementToAbove: (index: number): void => {
			dispatch(MakingFormElementsAction.addElementToAbove(index));
		},
		addElementToBelow: (index: number): void => {
			dispatch(MakingFormElementsAction.addElementToBelow(index));
		},
		deleteElement: (index: number): void => {
			dispatch(MakingFormElementsAction.deleteElement(index));
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(MakingFormElements);
