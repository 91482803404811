import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import Login, {LoginProps} from "components/login/Login";
import * as MainAction from "components/main/MainAction";
import * as ApplicationNames from "constants/ApplicationNames";

interface DispatchFromProps {
	login: (email: string, password: string) => void;
	openRegisterAccount: () => void; // 新規登録画面を開く
	openRegisterPasswordSendMail: () => void; // パスワード再登録画面を開く
}

export function mapStoreToProps(store: RootStore): Partial<LoginProps> {
	return {};
}

export function mapDispatchToProps(dispatch: Dispatch<MainAction.MainAction>): DispatchFromProps {
	return {
		login: (email: string, password: string): void => {
			dispatch(MainAction.login(email, password));
		},
		openRegisterAccount: (): void => {
			dispatch(MainAction.setCenteringLayoutCurrentApp(ApplicationNames.REGISTER_ACCOUNT));
		},
		openRegisterPasswordSendMail: (): void => {
			dispatch(
				MainAction.setCenteringLayoutCurrentApp(
					ApplicationNames.REREGISTER_PASSWORD_SEND_MAIL
				)
			);
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(Login);
