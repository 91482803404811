import TextButton from "components/commons/buttons/textButton/TextButton";

import * as Color from "constants/Colors";

export interface DialogMainButtonStore {
	text: string; // buttonに表示するtext
	margin?: string; //margin: 上　右　下　左
}

export interface DialogMainButtonProps extends DialogMainButtonStore {
	action: () => void; // clickした時に実行する関数
}

function DialogMainButton(props: DialogMainButtonProps): JSX.Element {
	return (
		<TextButton
			text={props.text}
			action={props.action}
			backgroundColor="#FFFF00"
			//			fontSize="20px"
			fontColor={Color.LightBaseBlackColor}
			fontWeight="bold"
			width="50%"
			height="50px"
			borderWidth="0"
			radius="3px"
			margin={props.margin}
		/>
	);
}

export default DialogMainButton;
