// ■■■■■■■■■■ Main
export const MAIN_SET_EMAIL = "MAIN_SET_EMAIL";
export type TYPE_MAIN_SET_EMAIL = typeof MAIN_SET_EMAIL;

// login
export const MAIN_LOGIN = "MAIN_LOGIN";
export type TYPE_MAIN_LOGIN = typeof MAIN_LOGIN;

// logout
export const MAIN_LOGOUT = "MAIN_LOGOUT";
export type TYPE_MAIN_LOGOUT = typeof MAIN_LOGOUT;

export const MAIN_SET_CENTERING_LAYOUT_CURRENT_APP = "MAIN_SET_CENTERING_LAYOUT_CURRENT_APP";
export type TYPE_MAIN_SET_CENTERING_LAYOUT_CURRENT_APP =
	typeof MAIN_SET_CENTERING_LAYOUT_CURRENT_APP;

export const MAIN_SET_MAIN_LAYOUT_CURRENT_APP = "MAIN_SET_MAIN_LAYOUT_CURRENT_APP";
export type TYPE_MAIN_SET_MAIN_LAYOUT_CURRENT_APP = typeof MAIN_SET_MAIN_LAYOUT_CURRENT_APP;

// MakingFormBasic
export const MAKINGFORMBASIC_SET_TITLE = "MAKINGFORMBASIC_SET_TITLE";
export type TYPE_MAKINGFORMBASIC_SET_TITLE = typeof MAKINGFORMBASIC_SET_TITLE;

export const MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN =
	"MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN";
export type TYPE_MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN =
	typeof MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_BEGIN;

export const MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN =
	"MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN";
export type TYPE_MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN =
	typeof MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_BEGIN;

export const MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END =
	"MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END";
export type TYPE_MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END =
	typeof MAKINGFORMBASIC_SET_IS_SET_OPEN_PERIOD_DATE_END;

export const MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END =
	"MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END";
export type TYPE_MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END =
	typeof MAKINGFORMBASIC_SET_OPEN_PERIOD_SPECIFIED_DATE_END;

// MakingFormElements
export const MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE = "MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE";
export type TYPE_MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE =
	typeof MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE;
export const MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW = "MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW";
export type TYPE_MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW =
	typeof MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW;
export const MAKINGFORMELEMENTS_DELETE_ELEMENT = "MAKINGFORMELEMENTS_DELETE_ELEMENT";
export type TYPE_MAKINGFORMELEMENTS_DELETE_ELEMENT = typeof MAKINGFORMELEMENTS_DELETE_ELEMENT;

// MakingFormThanks
export const MAKINGFORMTHANKS_SET_TITLE = "MAKINGFORMTHANKS_SET_TITLE";
export type TYPE_MAKINGFORMTHANKS_SET_TITLE = typeof MAKINGFORMTHANKS_SET_TITLE;
export const MAKINGFORMTHANKS_SET_MESSAGE = "MAKINGFORMTHANKS_SET_MESSAGE";
export type TYPE_MAKINGFORMTHANKS_SET_MESSAGE = typeof MAKINGFORMTHANKS_SET_MESSAGE;
