import * as ScreenLayoutsStyle from "styles/ScreenLayoutsStyle";

import imgMuraKimoNoTest from "resources/MuraKimoNoTest.png";

export interface CenteringLayoutStore {
	children?: React.ReactNode;
}

export interface CenteringLayoutProps extends CenteringLayoutStore {}

function CenteringLayout(props: CenteringLayoutProps): JSX.Element {
	//	return <>{props.children}</>;
	return (
		<ScreenLayoutsStyle.CenteringLayoutOutside>
			<div>
				<img src={imgMuraKimoNoTest} style={{width: "280px"}} />
			</div>
			{props.children}
		</ScreenLayoutsStyle.CenteringLayoutOutside>
	);
}

export default CenteringLayout;
