/**
 * NULLまたはundefinedかを検証する
 *
 * @export
 * @param {any} target チェックする値
 * @returns {boolean} 判定結果
 * - true: パラメーターがnullか、undefinedかのいずれか
 * - false: trueの条件に該当しない
 */
export function isNullOrUndefined(target: any): boolean {
	if (target === null || typeof target === "undefined") {
		return true;
	}
	return false;
}

/**
 * NULLまたはundefinedまたは空文字かどうかを検証する
 *
 * @export
 * @param {string | null | undefined} target チェックする値(文字列)
 * @returns {boolean} 判定結果
 * - true: パラメーターがnullか、undefinedか、空文字のいずれか
 * - false: trueの条件に該当しない
 */
export function isNullOrUndefinedOrEmpty(target: string | null | undefined): boolean {
	const checkResult = isNullOrUndefined(target);
	if (checkResult === true) {
		return true;
	}
	return target === "";
}

/**
 * Date型の日付を文字列に変換する
 *
 * @export
 * @param {Date} target 日付
 * @param {boolean} isForDisp 日付文字列が表示用かどうか
 * - true: 表示用フォーマット 2022/02/22
 * - false: HTML日付入力用フォーマット 2022-02-22
 * @param {boolean} isDateOnly 文字列に時間を含めるかどうか
 * - true: 日付のみ
 * - false: 日付と時間
 * @returns {boolean} 日付文字列
 */
export function getDateString(target: Date, isForDisp: boolean, isDateOnly: boolean): string {
	const delimiter: string = isForDisp === true ? "/" : "-";

	const year: number = target.getFullYear();
	const month: string = (target.getMonth() + 1).toString().padStart(2, "0");
	const day: string = target.getDate().toString().padStart(2, "0");
	const dateString: string = `${year}${delimiter}${month}${delimiter}${day}`;
	if (isDateOnly === true) {
		return dateString;
	}

	const hours: string = target.getHours().toString().padStart(2, "0");
	const minutes: string = target.getMinutes().toString().padStart(2, "0");
	const timeString: string = `${hours}:${minutes}`;

	const timeDelimiter: string = isForDisp === true ? " " : "T";

	const result: string = `${dateString}${timeDelimiter}${timeString}`;

	return result;
}

/**
 * 配列に任意の値を追加する
 * - インデックスの指定がマイナスの場合は最小インデックス値(0)とみなす
 * - インデックスの指定が要素数を超えている場合は最大インデックス値とみなす
 *
 * @export
 * @param {any[]} currentArray 元の配列
 * @param {any} value 追加する値
 * @param {number} target 編集対象インデックス
 * @param {boolean} isUpper インデックスの上に追加するか下に追加するか
 * - true: 上に追加する
 * - false: 下に追加する
 * @returns {any[]} 編集後の配列
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function addItemToAnyArray(
	currentArray: any[],
	value: any,
	target: number,
	isUpper: boolean
): any[] {
	if (Array.isArray(currentArray) === false) {
		return currentArray;
	}

	const targetIndex: number =
		target < 0 ? 0 : target >= currentArray.length - 1 ? currentArray.length - 1 : target;

	let newArray: any[] = [];

	switch (isUpper) {
		case true: {
			// 上に追加
			const frontPart: any[] = currentArray.slice(0, targetIndex);
			const rearPart: any[] = currentArray.slice(targetIndex, currentArray.length);
			frontPart.push(value);
			newArray = frontPart.concat(rearPart);
			break;
		}
		case false: {
			// 下に追加
			const frontPart: any[] = currentArray.slice(0, targetIndex + 1);
			const rearPart: any[] = currentArray.slice(targetIndex + 1, currentArray.length);
			frontPart.push(value);
			newArray = frontPart.concat(rearPart);
			break;
		}
		default: {
			return newArray;
		}
	}

	return newArray;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * 配列から指定したインデックスの要素を削除する
 * - インデックスの指定がマイナスの場合は最小インデックス値(0)とみなす
 * - インデックスの指定が要素数を超えている場合は最大インデックス値とみなす
 *
 * @export
 * @param {any[]} currentArray 元の配列
 * @param {number} target 編集対象インデックス
 * @returns {any[]} 編集後の配列
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export function deleteItemFromAnyArray(currentArray: any[], target: number): any[] {
	if (Array.isArray(currentArray) === false) {
		return currentArray;
	}

	const targetIndex: number =
		target < 0 ? 0 : target >= currentArray.length - 1 ? currentArray.length - 1 : target;

	const newArray: any[] = currentArray.filter((value: any, index: number) => {
		return index !== targetIndex;
	});

	return newArray;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export function handleDragOver(e: React.DragEvent<HTMLElement>): void {
	e.preventDefault();
}

export function getDataFromEvent(e: React.DragEvent<HTMLElement>): string[] {
	const dragData: string = e.dataTransfer.getData("text");
	const dropDatas: string[] = dragData.split(":");
	return dropDatas;
}

/**
 * 今日からN日目の日付を取得する
 * @export
 * @param {number} days N日
 * @returns {Date} 対象日付
 */
export function getNthDaysLater(days: number): Date {
	const targetDate = new Date();
	targetDate.setDate(targetDate.getDate() + days);
	targetDate.setHours(0);
	targetDate.setMinutes(0);
	targetDate.setMinutes(0);
	targetDate.setSeconds(0);
	targetDate.setMilliseconds(0);
	return targetDate;
}
