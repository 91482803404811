import TextButton from "components/commons/buttons/textButton/TextButton";

import * as Color from "constants/Colors";

export interface DialogSubButtonStore {
	text: string; // buttonに表示するtext
	margin?: string; //margin: 上　右　下　左
}

export interface DialogSubButtonProps extends DialogSubButtonStore {
	action: () => void; // clickした時に実行する関数
}

function DialogSubButton(props: DialogSubButtonProps): JSX.Element {
	return (
		<TextButton
			text={props.text}
			action={props.action}
			backgroundColor="#85E71D"
			//			fontSize="14px"
			fontColor={Color.LightBaseBlackColor}
			width="30%"
			height="40px"
			borderWidth="0"
			radius="3px"
			margin={props.margin}
		/>
	);
}

export default DialogSubButton;
