import * as Actions from "constants/Actions";
import {
	MakingFormElementsStore,
	FormElementProperty,
} from "components/makingForm/form/MakingFormElements";
import {MakingFormElementsAction} from "components/makingForm/form/MakingFormElementsAction";

import * as CommonFunc from "utilities/CommonFunc";

const defaultElementProperty: FormElementProperty = {
	isRequired: false,
	title: "",
	type: "",
	property: "",
};

export const initialStore: MakingFormElementsStore = {
	formElements: [defaultElementProperty],
};

export function makingFormElementsReducer(
	makingFormElementsStore: MakingFormElementsStore = initialStore,
	makingFormElementsAction: MakingFormElementsAction
): MakingFormElementsStore {
	switch (makingFormElementsAction.type) {
		// フォーム要素格納storeの対象行上に空のフォーム要素を追加する
		case Actions.MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE: {
			//			const currentElements: ElementProperty[] = {...formStore.formElements};
			const currentElements: FormElementProperty[] = makingFormElementsStore.formElements;
			const index: number = makingFormElementsAction.args.index;
			const newElements: FormElementProperty[] = CommonFunc.addItemToAnyArray(
				currentElements,
				defaultElementProperty,
				index,
				true
			);
			return {...makingFormElementsStore, formElements: newElements};
		}
		// フォーム要素格納storeの対象行下に空のフォーム要素を追加する
		case Actions.MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW: {
			const currentElements: FormElementProperty[] = makingFormElementsStore.formElements;
			const index: number = makingFormElementsAction.args.index;
			const newElements: FormElementProperty[] = CommonFunc.addItemToAnyArray(
				currentElements,
				defaultElementProperty,
				index,
				false
			);
			return {...makingFormElementsStore, formElements: newElements};
		}
		// フォーム要素格納storeの対象行フォーム要素を削除する
		case Actions.MAKINGFORMELEMENTS_DELETE_ELEMENT: {
			const currentElements: FormElementProperty[] = makingFormElementsStore.formElements;
			const index: number = makingFormElementsAction.args.index;
			const newElements: FormElementProperty[] = CommonFunc.deleteItemFromAnyArray(
				currentElements,
				index
			);
			return {...makingFormElementsStore, formElements: newElements};
		}
		default: {
			return {...makingFormElementsStore};
		}
	}
}

export default makingFormElementsReducer;
