import * as Actions from "constants/Actions";

// ■■■■■■■■■■ interface
// login
export interface Login {
	type: Actions.TYPE_MAIN_LOGIN;
	args: {
		email: string;
		password: string;
	};
}

export interface Logout {
	type: Actions.TYPE_MAIN_LOGOUT;
	args: {};
}

export interface SetCenteringLayoutCurrentApp {
	type: Actions.TYPE_MAIN_SET_CENTERING_LAYOUT_CURRENT_APP;
	args: {appName: string};
}

export interface SetMainLayoutCurrentApp {
	type: Actions.TYPE_MAIN_SET_MAIN_LAYOUT_CURRENT_APP;
	args: {appName: string};
}

// ■■■■■■■■■■ type
export type MainAction = Login | Logout | SetCenteringLayoutCurrentApp | SetMainLayoutCurrentApp;

// ■■■■■■■■■■ function
export function login(email: string, password: string): Login {
	return {
		type: Actions.MAIN_LOGIN,
		args: {
			email,
			password,
		},
	};
}

export function logout(): Logout {
	return {
		type: Actions.MAIN_LOGOUT,
		args: {},
	};
}

export function setCenteringLayoutCurrentApp(appName: string): SetCenteringLayoutCurrentApp {
	return {
		type: Actions.MAIN_SET_CENTERING_LAYOUT_CURRENT_APP,
		args: {appName},
	};
}

export function setMainLayoutCurrentApp(appName: string): SetMainLayoutCurrentApp {
	return {
		type: Actions.MAIN_SET_MAIN_LAYOUT_CURRENT_APP,
		args: {appName},
	};
}
