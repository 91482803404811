import * as Actions from "constants/Actions";

// ■■■■■■■■■■ interface
export interface AddElementToAbove {
	type: Actions.TYPE_MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE;
	args: {index: number};
}
export interface AddElementToBelow {
	type: Actions.TYPE_MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW;
	args: {index: number};
}
export interface DeleteElement {
	type: Actions.TYPE_MAKINGFORMELEMENTS_DELETE_ELEMENT;
	args: {index: number};
}

// ■■■■■■■■■■ type
export type MakingFormElementsAction = AddElementToAbove | AddElementToBelow | DeleteElement;

// ■■■■■■■■■■ function
export function addElementToAbove(index: number): AddElementToAbove {
	return {
		type: Actions.MAKINGFORMELEMENTS_ADD_ELEMENT_TO_ABOVE,
		args: {
			index,
		},
	};
}
export function addElementToBelow(index: number): AddElementToBelow {
	return {
		type: Actions.MAKINGFORMELEMENTS_ADD_ELEMENT_TO_BELOW,
		args: {
			index,
		},
	};
}
export function deleteElement(index: number): DeleteElement {
	return {
		type: Actions.MAKINGFORMELEMENTS_DELETE_ELEMENT,
		args: {
			index,
		},
	};
}
