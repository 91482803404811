import {createGlobalStyle} from "styled-components";
import media from "constants/MediaDefinition";
import GenJyuuGothicPLight from "fonts/GenJyuuGothic-P-Light.woff2";
import FlopDesignFONT from "fonts/FlopDesignFONT.woff2";
import RroundedMgenplus from "fonts/rounded-mgenplus-2pp-regular.woff2";
import keifont from "fonts/keifont.woff2";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: 'GenJyuuGothicPLight';
        src: url(${GenJyuuGothicPLight}) format('woff2');
        font-style: normal;
        font-display: swap;
        font-smooth: always;
        -webkit-font-smoothing: antialiased;
    }
    @font-face {
        font-family: 'FlopDesignFONT';
        src: url(${FlopDesignFONT}) format('woff2');
        font-style: normal;
        font-display: swap;
        font-smooth: always;
        -webkit-font-smoothing: antialiased;
    }
    @font-face {
        font-family: 'keifont';
        src: url(${keifont}) format('woff2');
        font-style: normal;
        font-display: swap;
        font-smooth: always;
        -webkit-font-smoothing: antialiased;
    }
    html {
        font-size: 14px;
        background-color: white;
		${media.smp} {
            font-size: 16px;
			background-color: whitesmoke;
		}
    }
    body {
		font-size: 16px;
		${media.smp} {
            font-size: 16px;
		}
        font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    }
	input {
		font-size: 16px;
		${media.smp} {
            font-size: 16px;
		}
	}
	select {
		font-size: 16px;
		${media.smp} {
            font-size: 16px;
		}
	}
	textarea {
		font-size: 16px;
		${media.smp} {
            font-size: 16px;
		}
	}
	button {
		font-size: 16px;
		${media.smp} {
            font-size: 14px;
		}
        user-select:none;
	}
`;

export default GlobalStyle;

/*
    body {
        font-family: Verdana, Roboto, "Droid Sans", "游ゴシック", YuGothic, "メイリオ", Meiryo, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "ＭＳ Ｐゴシック", sans-serif;
    }    

body {
    font-family: "Helvetica Neue",
      Arial,
      "Hiragino Kaku Gothic ProN",
      "Hiragino Sans",
      Meiryo,
      sans-serif;
}
*/
