import * as React from "react";
import * as ReactDOM from "react-dom";

import DialogMainButton from "components/commons/buttons/dialogMainButton/DialogMainButton";
import DialogSubButton from "components/commons/buttons/dialogSubButton/DialogSubButton";

import * as LoginStyle from "styles/LoginStyle";
import * as CommonStyle from "styles/CommonStyle";

import * as Validation from "utilities/Validation";
import * as Messages from "constants/Messages";
import * as ui from "utilities/Ui";

export interface LoginStore {}

export interface LoginProps extends LoginStore {
	login: (email: string, password: string) => void;
	openRegisterAccount: () => void; // 新規登録画面を開く
	openRegisterPasswordSendMail: () => void; // パスワード再登録画面を開く
}

function Login(props: LoginProps): JSX.Element {
	const [email, setEmail] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const [passwordInputType, setPasswordInputType] = React.useState<string>("password");
	const [isChecked, setIsChecked] = React.useState<boolean>(false);
	const [validationMessage, setValidationMessage] = React.useState<string>("");

	function changeInputValue(e: React.ChangeEvent<HTMLInputElement>): void {
		const fieldName: string = e.target.name;
		switch (fieldName) {
			case "email": {
				setEmail(e.target.value.replace(Validation.newLineValidationRegex, ""));
				break;
			}
			case "password": {
				setPassword(e.target.value.replace(Validation.newLineValidationRegex, ""));
				break;
			}
			case "changeType": {
				if (isChecked) {
					setPasswordInputType("password");
				} else {
					setPasswordInputType("text");
				}
				setIsChecked(!isChecked);
				break;
			}
			default: {
				break;
			}
		}
	}

	// login
	function login(): void {
		const isValidLoginId: boolean = Validation.isValidEmail(email);
		const isValidPassword: boolean = Validation.isValidPassword(password);
		if (!isValidPassword && !isValidLoginId) {
			setValidationMessage(Messages.LOGIN_ERROR_1);
			return;
		}
		if (!isValidLoginId) {
			setValidationMessage(Messages.LOGIN_ERROR_2);
			return;
		}
		if (!isValidPassword) {
			setValidationMessage(Messages.LOGIN_ERROR_3);
			return;
		}
		setValidationMessage("");
		props.login(email, password);
	}

	return (
		<>
			<div>
				<LoginStyle.TextError>{validationMessage}</LoginStyle.TextError>
				<LoginStyle.Text>ログインID（メールアドレス）</LoginStyle.Text>
				<br />
				<LoginStyle.TextBox
					type="email"
					name="email"
					value={email}
					maxLength={256}
					autoComplete="email"
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						changeInputValue(e);
					}}
				/>
				<br />
				<LoginStyle.Text>パスワード</LoginStyle.Text>
				<br />
				<LoginStyle.TextBox
					type={passwordInputType}
					name="password"
					value={password}
					autoComplete="password"
					onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
						changeInputValue(e);
					}}
				/>
				<br />
				<CommonStyle.FlexboxHorizontal alignItems="center" justifyContent="center">
					<input
						type="checkbox"
						name="changeType"
						checked={isChecked}
						onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
							changeInputValue(e);
						}}
					/>
					<LoginStyle.TextSmall>パスワードを表示する</LoginStyle.TextSmall>
				</CommonStyle.FlexboxHorizontal>
			</div>
			<CommonStyle.FlexboxHorizontal alignItems="center" justifyContent="center">
				<DialogMainButton
					text="ログイン"
					action={(): void => {
						login();
					}}
				/>
			</CommonStyle.FlexboxHorizontal>
			<CommonStyle.FlexboxHorizontal alignItems="center" justifyContent="center">
				<DialogSubButton
					text="新規登録"
					margin="0 50px 0 0" // 上　右　下　左
					action={(): void => {
						props.openRegisterAccount();
					}}
				/>
				<DialogSubButton
					text="パスワード|再登録"
					action={(): void => {
						props.openRegisterPasswordSendMail();
					}}
				/>
			</CommonStyle.FlexboxHorizontal>
		</>
	);
}

export default Login;
