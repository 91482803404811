export const LOGIN = "LOGIN"; // ログイン
export const REGISTER_ACCOUNT = "REGISTER_ACCOUNT"; // ユーザ登録
export const REGISTER_ACCOUNT_SUCCEED = "REGISTER_ACCOUNT_SUCCEED"; // ユーザ登録成功
export const REGISTER_ACCOUNT_FAILED = "REGISTER_ACCOUNT_FAILED"; // ユーザ登録失敗
export const REREGISTER_PASSWORD_SEND_MAIL = "REREGISTER_PASSWORD_SEND_MAIL"; // パスワード再登録メール送信
export const REREGISTER_PASSWORD_SEND_MAIL_SUCCEED = "REREGISTER_PASSWORD_SEND_MAIL_SUCCEED"; // パスワード再登録メール送信成功
export const REREGISTER_PASSWORD_SEND_MAIL_FAILED = "REREGISTER_PASSWORD_SEND_MAIL_FAILED"; // パスワード再登録メール送信失敗
export const REREGISTER_PASSWORD_INPUT_PASSWORD = "REREGISTER_PASSWORD_INPUT_PASSWORD"; // パスワード再登録パスワード入力
export const REREGISTER_PASSWORD_INPUT_PASSWORD_SUCCEED =
	"REREGISTER_PASSWORD_INPUT_PASSWORD_SUCCEED"; // パスワード再登録パスワード入力成功
export const REREGISTER_PASSWORD_INPUT_PASSWORD_FAILED =
	"REREGISTER_PASSWORD_INPUT_PASSWORD_FAILED"; // パスワード再登録パスワード入力失敗

export const LISTING_FORMS = "LISTING_FORMS"; // フォーム一覧
export const MAKING_FORM = "MAKING_FORM"; // フォーム作成
export const PREVIEWING_FORM = "PREVIEWING_FORM"; // プレビュー
export const VIEWING_USER_INFO = "VIEWING_USER_INFO"; // ユーザ情報
