import styled from "styled-components";

import * as Color from "constants/Colors";

import {MarginStyledProps} from "interfaces/StyleInterfaces";

// ダイアログのように表示枠を画面中心に表示するスタイル - 最外枠
export const CenteringLayoutOutside = styled.div`
	position: fixed;
	width: 90%;
	height: 90%;
	top: calc(10% / 2);
	left: calc(10% / 2 - 4px);
	border: solid 4px ${Color.ReqizThemeColor};
	background-color: white;
	display: grid;
	grid-template-rows: 25% max-content 20% 15%;
	grid-template-areas: "logo" "content" "button" "button_sub";
	text-align: center;
`;

// ダイアログのように表示枠を画面中心に表示するスタイル - 配置を制御するCSS
export const CenteringLayoutInsideControl = styled.div`
	display: flex;
`;
//text-align: center;
//display: flex;
//flex-direction: column;
//flex-wrap: nowrap;
//align-items: center;
const gggg = styled.div`
	height: 100%;
	margin: 0;
	display: grid;
	grid-template-rows: max-content 1fr max-content minmax(0, max-content);
	grid-template-columns: 1fr;
	grid-template-areas: "header" "content" "arrow" "launcher";
	grid-gap: 0;
`;

// ヘッダーとフッターがあるメインスタイル - 最外枠
export const MainLayoutOutside = styled.div`
	height: 100%;
	display: grid;
	grid-template-rows: 3rem min-content 1fr 0.8rem;
`;

// ヘッダーとフッターがあるメインスタイル - ヘッダー
export const MainLayoutHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: ${Color.ReqizThemeColor};
`;

// ヘッダーとフッターがあるメインスタイル - ヘッダー - コンテナ
export const MainLayoutHeaderContainer = styled.div`
	width: 100%;
	height: 100%;
`;

// ヘッダーとフッターがあるメインスタイル - コンテンツ固定部分
export const MainLayoutContentFixed = styled.div`
	${(props: MarginStyledProps): string => {
		if (props.marginTop === undefined) {
			return "";
		}
		return `margin-top: ${props.marginTop}`;
	}};
	${(props: MarginStyledProps): string => {
		if (props.marginBottom === undefined) {
			return "";
		}
		return `margin-bottom: ${props.marginBottom}`;
	}};
	${(props: MarginStyledProps): string => {
		if (props.marginLeft === undefined) {
			return "";
		}
		return `margin-left: ${props.marginLeft}`;
	}};
	${(props: MarginStyledProps): string => {
		if (props.marginRight === undefined) {
			return "";
		}
		return `margin-right: ${props.marginRight}`;
	}};
`;

// ヘッダーとフッターがあるメインスタイル - コンテンツ
export const MainLayoutContent = styled.div`
	overflow: scroll;
	${(props: MarginStyledProps): string => {
		if (props.marginTop === undefined) {
			return "";
		}
		return `margin-top: ${props.marginTop}`;
	}};
	${(props: MarginStyledProps): string => {
		if (props.marginBottom === undefined) {
			return "";
		}
		return `margin-bottom: ${props.marginBottom}`;
	}};
	${(props: MarginStyledProps): string => {
		if (props.marginLeft === undefined) {
			return "";
		}
		return `margin-left: ${props.marginLeft}`;
	}};
	${(props: MarginStyledProps): string => {
		if (props.marginRight === undefined) {
			return "";
		}
		return `margin-right: ${props.marginRight}`;
	}};
`;

// ヘッダーとフッターがあるメインスタイル - フッター
export const MainLayoutFooter = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #404040;
	color: ${Color.DarkBaseWhiteColor};
	font-size: 0.6rem;
`;
