import styled from "styled-components";

export const MuraKimoNo = styled.div``;

export const MuraKimoNoAccent = styled.div`
	font-color: red;
`;

export const Text = styled.span``;

export const TextSmall = styled.span``;

export const TextError = styled.p`
	color: red;
	height: 20px;
`;

export const TextBox = styled.input`
	width: 70%;
	height: 18px;
	border: solid 2px black;
	border-radius: 0;
`;
