export const customMediaQuery = (maxWidth: number): string => `@media (max-width: ${maxWidth}px)`;

export const media = {
	custom: customMediaQuery,
	desktop: customMediaQuery(1280),
	tablet: customMediaQuery(768),
	smp: customMediaQuery(480),
	phone: customMediaQuery(375),
};

export default media;
