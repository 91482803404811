import * as Actions from "constants/Actions";
import {MakingFormThanksStore} from "components/makingForm/thanks/MakingFormThanks";
import {MakingFormThanksAction} from "components/makingForm/thanks/MakingFormThanksAction";

import * as CommonFunc from "utilities/CommonFunc";

export const initialStore: MakingFormThanksStore = {
	title: "アンケートにご協力頂きありがとうございました！",
	message:
		"お忙しい中アンケートにご協力いただき、誠にありがとうございました。\nなお今回頂いた個人情報は本調査の目的以外には利用しません。",
};

export function makingFormBasicReducer(
	makingFormThanksStore: MakingFormThanksStore = initialStore,
	makingFormThanksAction: MakingFormThanksAction
): MakingFormThanksStore {
	switch (makingFormThanksAction.type) {
		case Actions.MAKINGFORMTHANKS_SET_TITLE: {
			const value: string = makingFormThanksAction.args.title;
			return {...makingFormThanksStore, title: value};
		}
		case Actions.MAKINGFORMTHANKS_SET_MESSAGE: {
			const value: string = makingFormThanksAction.args.message;
			return {...makingFormThanksStore, message: value};
		}
		default: {
			return {...makingFormThanksStore};
		}
	}
}

export default makingFormBasicReducer;
