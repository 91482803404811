import * as Actions from "constants/Actions";
import {MainStore} from "components/main/Main";
import {MainAction} from "components/main/MainAction";

import * as ApplicationNames from "constants/ApplicationNames";

export const initialStore: MainStore = {
	email: "",
	isLoggedIn: true,
	centeringLayoutCurrentApp: ApplicationNames.LOGIN,
	mainLayoutCurrentApp: ApplicationNames.LISTING_FORMS,
};

export function mainReducer(
	mainStore: MainStore = initialStore,
	mainAction: MainAction
): MainStore {
	switch (mainAction.type) {
		// login
		case Actions.MAIN_LOGIN: {
			const email = mainAction.args.email;
			const password = mainAction.args.password;
			console.log("email=" + email);
			console.log("password=" + password);
			const isLoggedIn: boolean = true;

			return {...mainStore, email: email, isLoggedIn: isLoggedIn};
		}
		// logout
		case Actions.MAIN_LOGOUT: {
			return {...mainStore, email: "", isLoggedIn: false};
		}

		case Actions.MAIN_SET_CENTERING_LAYOUT_CURRENT_APP: {
			const appName = mainAction.args.appName;

			return {...mainStore, centeringLayoutCurrentApp: appName};
		}

		case Actions.MAIN_SET_MAIN_LAYOUT_CURRENT_APP: {
			const appName = mainAction.args.appName;

			return {...mainStore, mainLayoutCurrentApp: appName};
		}

		default: {
			return {...mainStore};
		}
	}
}

export default mainReducer;
