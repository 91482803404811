import React, {useState} from "react";
import {OptionDefinition} from "interfaces/CommonInterfaces";

import * as ButtonStyle from "styles/ButtonStyle";

import {StyledComponent} from "styled-components";
import * as StyleInterfaces from "interfaces/StyleInterfaces";
import {ButtonGroupProps} from "styles/ButtonStyle";

export interface RadioButtonStore {
	options: OptionDefinition[]; // 選択肢
	currentValue: boolean | number | string;
	styledButton: StyledComponent<
		"button",
		React.FunctionComponent,
		StyleInterfaces.SelectButtonStyledProps,
		never
	>;
	isHorizontally: boolean; // true: 横並び false: 縦並び
	showComment: boolean; // コメントを表示するかどうか true:表示する false:非表示
	width?: string;
	height?: string;
}

export interface RadioButtonProps extends RadioButtonStore {
	setFunction: (value: boolean | number | string) => void; // 選択した時に値をセットする関数
}

function RadioButton(props: RadioButtonProps): JSX.Element {
	// コメント表示
	function displayComment() {
		const currentOption = props.options.filter((option: OptionDefinition) => {
			return option.value === props.currentValue;
		});
		const commentTemp = currentOption[0].comment;

		if (commentTemp === undefined || commentTemp === "") {
			return null;
		}

		return <ButtonStyle.ButtonGroupComment>{commentTemp}</ButtonStyle.ButtonGroupComment>;
	}

	return (
		<>
			{props.showComment === true && displayComment()}
			<ButtonStyle.ButtonGroup
				isHorizontally={props.isHorizontally}
				width={props.width}
				height={props.height}
			>
				{props.options.map((option: OptionDefinition, index: number) => {
					return (
						<props.styledButton
							key={option.value.toString()}
							isSelected={option.value === props.currentValue}
							isHorizontally={props.isHorizontally}
							onClick={(): void => {
								props.setFunction(option.value);
							}}
						>
							{option.disp}
						</props.styledButton>
					);
				})}
			</ButtonStyle.ButtonGroup>
		</>
	);
}

export default RadioButton;
