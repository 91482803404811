import {Dispatch} from "redux";
import {connect} from "react-redux";
import {RootStore} from "reducers/index";

import MakingFormBasic, {MakingFormBasicStore} from "components/makingForm/basic/MakingFormBasic";
import * as MakingFormBasicAction from "components/makingForm/basic/MakingFormBasicAction";

interface DispatchFromProps {
	setTitle: (title: string) => void;
	setOpenPeriodIsSetDateBegin: (isSetDateBegin: boolean) => void;
	setOpenPeriodDateBegin: (openPeriodDateBegin: Date) => void;
	setOpenPeriodIsSetDateEnd: (isSetDateEnd: boolean) => void;
	setOpenPeriodDateEnd: (openPeriodDateEnd: Date) => void;
}

export function mapStoreToProps(store: RootStore): MakingFormBasicStore {
	return {
		title: store.makingFormBasic.title,
		isSetOpenPeriodDateBegin: store.makingFormBasic.isSetOpenPeriodDateBegin,
		openPeriodSpecifiedDateBegin: store.makingFormBasic.openPeriodSpecifiedDateBegin,
		isSetOpenPeriodDateEnd: store.makingFormBasic.isSetOpenPeriodDateEnd,
		openPeriodSpecifiedDateEnd: store.makingFormBasic.openPeriodSpecifiedDateEnd,
	};
}

export function mapDispatchToProps(
	dispatch: Dispatch<MakingFormBasicAction.MakingFormBasicAction>
): DispatchFromProps {
	return {
		setTitle: (title: string): void => {
			dispatch(MakingFormBasicAction.setTitle(title));
		},
		setOpenPeriodIsSetDateBegin: (isSetDateBegin: boolean): void => {
			dispatch(MakingFormBasicAction.setOpenPeriodIsSetDateBegin(isSetDateBegin));
		},
		setOpenPeriodDateBegin: (openPeriodDateBegin: Date): void => {
			dispatch(MakingFormBasicAction.setOpenPeriodDateBegin(openPeriodDateBegin));
		},
		setOpenPeriodIsSetDateEnd: (isSetDateEnd: boolean): void => {
			dispatch(MakingFormBasicAction.setOpenPeriodIsSetDateEnd(isSetDateEnd));
		},
		setOpenPeriodDateEnd: (openPeriodDateEnd: Date): void => {
			dispatch(MakingFormBasicAction.setOpenPeriodDateEnd(openPeriodDateEnd));
		},
	};
}

export default connect(mapStoreToProps, mapDispatchToProps)(MakingFormBasic);
