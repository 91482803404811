import * as Color from "constants/Colors";

import * as ScreenLayoutsStyle from "styles/ScreenLayoutsStyle";
import * as CommonStyle from "styles/CommonStyle";

import {APP_VERSION} from "constants/Envs";

export interface MainLayoutStore {
	children?: React.ReactNode;
}

export interface MainLayoutProps extends MainLayoutStore {}

function MainLayout(props: MainLayoutProps): JSX.Element {
	const appVersion: string = APP_VERSION;

	return (
		<ScreenLayoutsStyle.MainLayoutOutside>
			{props.children}
			<ScreenLayoutsStyle.MainLayoutFooter>
				<div>Version {appVersion}</div>
				<div>Copyright © 2022 reqiz Inc. All Rights Reserved.</div>
			</ScreenLayoutsStyle.MainLayoutFooter>
		</ScreenLayoutsStyle.MainLayoutOutside>
	);
}

export default MainLayout;
