import MainLayout from "components/commons/screenLayouts/mainLayout/MainLayout";
import CenteringLayout from "components/commons/screenLayouts/centeringLayout/CenteringLayout";

import LoginConnect from "components/login/LoginConnect";
import RegisterAccountConnect from "components/registerAccount/RegisterAccountConnect";
import ReregisterPasswordSendMailConnect from "components/reregisterPasswordSendMail/ReregisterPasswordSendMailConnect";

import ListingFormsConnect from "components/listingForms/ListingFormsConnect";
import MakingFormConnect from "components/makingForm/MakingFormConnect";
import PreviewingForm from "components/previewingForm/PreviewingForm";
import ViewingUserInfo from "components/viewingUserInfo/ViewingUserInfo";

import GlobalStyle from "styles/GlobalStyle";

import * as ApplicationNames from "constants/ApplicationNames";

export interface SurveyFormsInfo {
	// アンケートフォーム情報
	id: string; // ID
	title: string; // タイトル
	isPublished: boolean; // 公開中かどうか true: 公開中
	publicationPeriodBegin: Date; // 公開期間 開始日時
	publicationPeriodEnd: Date; // 公開期間 終了日時
}

export interface SurveyForm {
	// アンケートフォーム
	id: string; // ID
	title: string; // タイトル
}

export interface MainStore {
	email: string; // ログインしているID
	isLoggedIn: boolean; // ログインしているかどうか true: ログインしている
	centeringLayoutCurrentApp: string; // センタリングレイアウトに表示するアプリ
	mainLayoutCurrentApp: string; // メインレイアウトに表示するアプリ
	//	surveyForms: SurveyFormsInfo[]; //
}

export interface MainProps extends MainStore {}

function Main(props: MainProps): JSX.Element {
	function selectCenteringLayoutApp(): JSX.Element {
		switch (props.centeringLayoutCurrentApp) {
			case ApplicationNames.LOGIN: {
				return <LoginConnect />;
				break;
			}
			case ApplicationNames.REGISTER_ACCOUNT: {
				return <RegisterAccountConnect />;
				break;
			}
			case ApplicationNames.REREGISTER_PASSWORD_SEND_MAIL: {
				return <ReregisterPasswordSendMailConnect />;
				break;
			}
			default: {
				return <></>;
				break;
			}
		}
		return <></>;
	}

	function selectMainLayoutApp(): JSX.Element {
		switch (props.mainLayoutCurrentApp) {
			case ApplicationNames.LISTING_FORMS: {
				return <ListingFormsConnect />;
				break;
			}
			case ApplicationNames.MAKING_FORM: {
				return <MakingFormConnect />;
				break;
			}
			case ApplicationNames.PREVIEWING_FORM: {
				return <PreviewingForm />;
				break;
			}
			case ApplicationNames.VIEWING_USER_INFO: {
				return <ViewingUserInfo />;
				break;
			}
			default: {
				return <></>;
				break;
			}
		}
		return <></>;
	}

	return (
		<>
			<GlobalStyle />
			{props.isLoggedIn === true ? (
				<MainLayout>{selectMainLayoutApp()}</MainLayout>
			) : (
				<CenteringLayout>{selectCenteringLayoutApp()}</CenteringLayout>
			)}
		</>
	);
}

export default Main;
