import React from "react";
import HeaderButton from "components/commons/buttons/headerButton/HeaderButton";

import MakingFormBasicConnect from "components/makingForm/basic/MakingFormBasicConnect";
import MakingFormElementsConnect from "components/makingForm/form/MakingFormElementsConnect";
import MakingFormThanksConnect from "components/makingForm/thanks/MakingFormThanksConnect";

import * as ScreenLayoutsStyle from "styles/ScreenLayoutsStyle";
import RadioButton from "components/commons/buttons/radioButton/RadioButton";

import {OptionDefinition} from "interfaces/CommonInterfaces";

import * as ButtonStyle from "styles/ButtonStyle";

export interface MakingFormStore {}

export interface MakingFormProps extends MakingFormStore {
	openListingForms: () => void;
}

function MakingForm(props: MakingFormProps): JSX.Element {
	const makingFormTab: OptionDefinition[] = [
		{value: "1", disp: "基本情報"},
		{value: "2", disp: "フォーム"},
		{value: "3", disp: "入力完了"},
	];

	const [currentTab, setCurrentTab] = React.useState<string>("1");

	function action(): void {
		console.log("ボタンタップ");
	}

	return (
		<>
			<ScreenLayoutsStyle.MainLayoutHeader>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="登録する"
						isAccentType
						action={(): void => {
							props.openListingForms();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="プレビュー"
						isAccentType={false}
						action={(): void => {
							action();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer></ScreenLayoutsStyle.MainLayoutHeaderContainer>
				<ScreenLayoutsStyle.MainLayoutHeaderContainer>
					<HeaderButton
						text="戻る"
						isAccentType={false}
						action={(): void => {
							props.openListingForms();
						}}
					/>
				</ScreenLayoutsStyle.MainLayoutHeaderContainer>
			</ScreenLayoutsStyle.MainLayoutHeader>
			<ScreenLayoutsStyle.MainLayoutContentFixed
				marginTop="0.5rem"
				marginLeft="0.5rem"
				marginRight="0.5rem"
			>
				<RadioButton
					options={makingFormTab}
					currentValue={currentTab}
					styledButton={ButtonStyle.TabSelectButton}
					isHorizontally={true}
					showComment={false}
					setFunction={(value: boolean | number | string): void => {
						setCurrentTab(value as string);
					}}
				/>
			</ScreenLayoutsStyle.MainLayoutContentFixed>
			<ScreenLayoutsStyle.MainLayoutContent marginLeft="0.3rem" marginRight="0.3rem">
				{currentTab === "1" && <MakingFormBasicConnect />}
				{currentTab === "2" && <MakingFormElementsConnect />}
				{currentTab === "3" && <MakingFormThanksConnect />}
			</ScreenLayoutsStyle.MainLayoutContent>
		</>
	);
}

export default MakingForm;
