import * as React from "react";
import * as ButtonStyle from "styles/ButtonStyle";

export interface ImageButtonStore {
	imagePath: string; // 画像のpublicフォルダ以下のパス
	alt: string; // altで設定する表示名
	imageWidth: string; // 単位: px
	imageHeight: string; // 単位: px
	buttonWidth?: string; // 単位: px
	buttonHeight?: string; // 単位: px
	text?: string;
}

export interface ImageButtonProps extends ImageButtonStore {
	action?: (e?: React.MouseEvent) => void; // clickした時に実行する関数
	onMouseEnter?: (e: React.MouseEvent) => void;
	onMouseLeave?: () => void;
}

function ImageButton(props: ImageButtonProps): JSX.Element {
	const paddingLeftRight: string =
		props.buttonWidth !== undefined && props.buttonWidth !== props.imageWidth
			? `calc((${props.buttonWidth} - ${props.imageWidth}) / 2) `
			: "0";
	const paddingTopBottom: string =
		props.buttonHeight !== undefined && props.buttonHeight !== props.imageHeight
			? `calc((${props.buttonHeight} - ${props.imageHeight}) / 2)`
			: "0";

	return (
		<ButtonStyle.ImageButton
			width={props.buttonWidth === undefined ? props.imageWidth : props.buttonWidth}
			height={props.buttonHeight === undefined ? props.imageHeight : props.buttonHeight}
			type="button"
			onClick={props.action}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			<ButtonStyle.ImgBlock
				src={props.imagePath}
				alt={props.alt}
				width={props.imageWidth}
				height={props.imageHeight}
				padding={`${paddingTopBottom} ${paddingLeftRight}`}
			/>
			{props.text !== undefined && props.text}
		</ButtonStyle.ImageButton>
	);
}

export default ImageButton;
