import * as CommonFunc from "utilities/CommonFunc";

export const newLineValidationRegex = /\r\n|\n|\r/gm;

/**
 * emailの検証
 *
 * @export
 * @param {string} email email
 * @returns {boolean} 判定結果
 * - true: 正しいemail形式
 * - false: 不正な文字が含まれている
 */
export function isValidEmail(email: string): boolean {
	const emailPattern = /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
	return emailPattern.test(email);
}

/**
 * passwordの検証
 *
 * @export
 * @param {string} password password
 * @returns {boolean} 判定結果
 * - true: 正しいpassword形式
 * - false: 不正な文字が含まれている
 */
export function isValidPassword(password: string): boolean {
	return (
		CommonFunc.isNullOrUndefinedOrEmpty(password) === false &&
		password.search(newLineValidationRegex) === -1
	);
}

export function isValidOpinionBoxMessage(message: string): boolean {
	return (
		message !== undefined && message.length > 0 && message.search(newLineValidationRegex) === -1
	);
}

/**
 * 半角英数字かをチェックする
 * @param targetString
 * @return 判定結果（true：半角英数字または0バイト）
 */
export function isHalfWidthChara(targetString: string): boolean {
	const isHalfWidthCharaRegex = /^[0-9a-zA-Z]*$/;
	return targetString !== undefined && isHalfWidthCharaRegex.test(targetString);
}

/**
 * 正の整数かをチェックする
 * @param {string} targetNumberStr
 * @return {boolean} 判定結果（true：正の整数または0バイト。カンマNG。）
 */
export function isInt(targetNumberStr: string): boolean {
	const isIntRegex = /^[0-9]*$/;
	return targetNumberStr !== undefined && isIntRegex.test(targetNumberStr);
}
/**
 * 数値かどうかチェックする（小数対応）
 * @param {string} targetNumberStr
 * @return {boolean} 判定結果（true：正の整数・小数。0バイトNG。カンマNG。）
 */
export function isNum(targetNumberStr: string): boolean {
	const isNumRegex = /^[0-9]+(\.[0-9]+)?$/;
	return targetNumberStr !== undefined && isNumRegex.test(targetNumberStr);
}
